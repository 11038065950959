// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-post-tsx": () => import("./../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-tag-tsx": () => import("./../src/templates/tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-me-tsx": () => import("./../src/pages/about-me.tsx" /* webpackChunkName: "component---src-pages-about-me-tsx" */),
  "component---src-pages-contact-me-tsx": () => import("./../src/pages/contact-me.tsx" /* webpackChunkName: "component---src-pages-contact-me-tsx" */),
  "component---src-pages-useful-stuff-tsx": () => import("./../src/pages/useful-stuff.tsx" /* webpackChunkName: "component---src-pages-useful-stuff-tsx" */)
}

